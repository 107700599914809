import React, { useEffect } from 'react'
import { Flex, jsx, Box, Image, Input } from 'theme-ui'
import Compute from 'components/compute'
import Layout from 'components/layout'
import SEO from 'components/seo'

export default function Estimate() {
  return (
    <Layout>
      <SEO
        title="Estimate"
        description="Get your estimate now! Only at Printado Printing Services"
      />

      <Flex sx={{ justifyContent: 'center' }} p={[2, 4, 6]}>
        <Compute />
      </Flex>
    </Layout>
  )
}
